import React, { useEffect } from 'react';
import { Checkbox, Button, Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

import FormInput from './CustomTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from './Checkout/styles';
import { TextField, RadioGroup, Radio } from '@material-ui/core';
// import postals from '../../assets/postals.json';
// import promocodes from '../../assets/promodelivery.json';
import { API_URL, DELIVERY_METHOD, PICKUP_METHOD, PICKUP_BALESTIER, PICKUP_CHINATOWN, PREFERRED_NONE, PREFERRED_MORNING, PREFERRED_AFTERNOON } from '../commons'
import '../../i18n/i18n';
import {useTranslation} from 'react-i18next';

var postals = [];
const CollectionForm = ({ next, collectionData, peakPeriod, promocodes, backStep }) => {
  const {t, i18n} = useTranslation();
  const methods = useForm({
    defaultValues: {
      postal:collectionData.postal?collectionData.postal.slice():"",
      shop:collectionData.shop?collectionData.shop.slice():"",
      address:collectionData.address?collectionData.address.slice():"",
      promocode: collectionData.promocode?collectionData.promocode.slice():"",
    }
  })

  const getToday = () => {
    const today = new Date();
    var todayFormat = today.getFullYear();
    todayFormat += "-";
    todayFormat += (today.getMonth()+1)>10 ? today.getMonth()+1:"0"+(today.getMonth()+1);
    todayFormat += "-";
    todayFormat += today.getDate()>10 ? today.getDate():"0"+today.getDate();
    return todayFormat;
  }

  const classes = useStyles();

  const PinkCheckbox = withStyles({
    root: {
      color: pink[400],
      '&$checked': {
        color: pink[100],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [state, setState] = React.useState({
    contactlessAck: collectionData.collectionMethod?collectionData.collectionMethod.slice():false,
    collectionMethod: collectionData.collectionMethod?collectionData.collectionMethod.slice():DELIVERY_METHOD,
    collectionPlace: collectionData.collectionPlace?collectionData.collectionPlace.slice():PICKUP_CHINATOWN,
    preferredSession: collectionData.preferredSession?collectionData.preferredSession.slice():PREFERRED_AFTERNOON,
    deliveryCharge: 8,
    peakPrice: 0,
    promoCodeAccepted: null,
    promoCodeAcceptedMsg: "",
    promoCodeAcceptedDate: "",
    promoCodeAcceptedPostal: "",
    chosenDate: collectionData.chosenDate?collectionData.chosenDate.slice():null
  });

  const fetchPostals = async () => {
    fetch(API_URL+"/postal", {
      "method": "GET",
      "headers": {
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .then(response => response.json())
    .then(response => {
      console.log('set postal');
      console.log(response.length);
      postals = response;
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchPostals();
  }, []);  

  const calculateDelivery = (postal) => {
    // peak period delivery suppresses all promotions
    const peakPrice = checkForPeak();

    if (peakPrice > 0) {
      console.log("it's peak!");
      if (state.peakPrice !== peakPrice || state.deliveryCharge !== peakPrice ) {
        setState({ ...state, deliveryCharge: peakPrice, peakPrice: peakPrice, promoCodeAccepted:null});
      }
      return;
    }

    postal = postal.trim();
    let sector = postal.substring(0, 2);
    var charges = "8";
    console.log(postals.length);
    for (let i = 0; i < postals.length; i++) {
      if (postals[i].sectors.includes(sector)) {
        charges = postals[i].cost;
        // Sentosa
        if (postal.startsWith("098")) {
          charges = "10";
        }
        break;
      }
    }
    
    if (state.promoCodeAccepted === null || postal !== state.promoCodeAcceptedPostal) {
      if (state.deliveryCharge !== charges) {
        console.log("set 2");
        setState({ ...state, deliveryCharge: charges, promoCodeAccepted: null, promoCodeAcceptedMsg: '', promoCodeAcceptedPostal: '' });
      }
    }
  }

  const promoDelivery = (promoCode) => {
    // peak period delivery suppresses all promotions
    const peakPrice = checkForPeak();

    if (peakPrice > 0) {
      console.log("it's peak!");
      if (state.peakPrice !== peakPrice || state.deliveryCharge !== peakPrice ) {
        setState({ ...state, deliveryCharge: peakPrice, peakPrice: peakPrice, promoCodeAccepted:null});
      }
      return;
    }

    findDeliveryCost(promoCode);
  }

  function findDeliveryCost(promoCode) {
    promoCode = promoCode.trim();
    var charges = state.deliveryCharge;
    var promoCodeAccepted = null;
    var promoCodeAcceptedMsg = "";
    var promoCodeAcceptedDate = null;
    var promoCodeAcceptedPostal = "";
    for (let i = 0; i < promocodes.length; i++) {
      // find a match for promocode
      if (promocodes[i].code.toUpperCase() === promoCode.toUpperCase()) {
        // delivery date must match the promo
        // time of order must be within the delivery start and end dates
        // delivery area must be as indicated, [] means all
        const promoDelivery = new Date(promocodes[i].deliveryDate);
        const chosenDate = new Date(state.chosenDate);
        const startDate = new Date(promocodes[i].startDate);
        const endDate = new Date(promocodes[i].endDate);
        const now = new Date();
        var areaAccepted = true;
        var areas = JSON.parse(promocodes[i].areas);
        console.log(areas);
        for (let j = 0; j < areas.length; j++) {
          areaAccepted = false;
          console.log(areas[j]);
          if (watchPostalCode.length === 6 && watchPostalCode.substring(0, 2) === areas[j]) {
            areaAccepted = true;
            console.log('area accepted for promo!');
            break;
          }
        }
        // watchPostalCode
        if (promoDelivery.toDateString() === chosenDate.toDateString() && now >= startDate && now <= endDate && areaAccepted) {
          charges = promocodes[i].amount;
          promoCodeAccepted = promoCode;
          promoCodeAcceptedMsg = i18n.language === "ch"? promocodes[i].cMsg:promocodes[i].msg;
          promoCodeAcceptedDate = chosenDate;
          promoCodeAcceptedPostal = watchPostalCode;
        }
        break;
      }
    }
    
    if (promoCodeAccepted !== state.promoCodeAccepted) {
      setState({ ...state, deliveryCharge: charges, promoCodeAccepted: promoCodeAccepted, promoCodeAcceptedMsg: promoCodeAcceptedMsg, promoCodeAcceptedPostal: promoCodeAcceptedPostal});
    }
  }

  function checkForPeak() {
    // peak period delivery suppresses all promotions
    var peakPrice = 0;
    for (let j = 0; j < peakPeriod.length; j++) {
      const chosenDate = new Date(state.chosenDate);
      const peakDate = new Date(peakPeriod[j].date);
      if (peakDate.toDateString() === chosenDate.toDateString()) {
        peakPrice = parseFloat(peakPeriod[j].cost).toFixed(2);
        break;
      }
    }
    return peakPrice;
  }

  const watchPostalCode = methods.watch("postal");
  if (watchPostalCode.trim().length === 6 && watchPostalCode.trim().match(/\D/) === null?true:false) {
    console.log(watchPostalCode);
    setTimeout(function() {
      calculateDelivery(watchPostalCode);
    }, 200);
  }

  const watchPromoCode = methods.watch("promocode");
  if (watchPromoCode.length > 0 && watchPromoCode !== state.promoCodeAccepted) {
    setTimeout(function() {
      console.log('check promo!');
      promoDelivery(watchPromoCode);
    }, 200);
  }

  const checkContactless = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleRadioCollectionChange = (event) => {
    setState({ ...state, collectionMethod: event.target.value });
  };

  const handleRadioPlaceChange = (event) => {
    setState({ ...state, collectionPlace: event.target.value });
  };

  const handleRadioSessionChange = (event) => {
    setState({ ...state, preferredSession: event.target.value });
  };

  const data = (d) => {
    if (!state.chosenDate) {
      alert(t("cDDateAlert"));
      return;
    }
    if(state.collectionMethod === DELIVERY_METHOD) {
      if (!state.contactlessAck) {
        alert(t("cDisclaimerAlert"));
        return;
      }
      next({...d, collectionMethod:state.collectionMethod, preferredSession:state.preferredSession, contactlessAck:state.contactlessAck, chosenDate:state.chosenDate, deliveryCharge:state.deliveryCharge});
      return;
    } 

    // pickup
    next({...d, collectionMethod:state.collectionMethod, collectionPlace:state.collectionPlace, chosenDate:state.chosenDate, contactlessAck:state.contactlessAck, deliveryCharge:0});
  }

  // handles when user changes input in date inputfield
  const handleChangeDate = (event) => {
      console.log("set 9");
      setState({ ...state, chosenDate: event.target.value });
  };

  useEffect(() => { 
    if (state.promoCodeAccepted !== null && state.chosenDate !== state.promoCodeAcceptedDate) {
      console.log('check promo!');
      promoDelivery(state.promoCodeAccepted);
    }
  }, [state.chosenDate]);

  return (
    <>
      <div className={classes.stepTitle}>{t("collectionMethod")}</div>
      {/* <div className={classes.announcement}>Note: Our orders are full for 18 Jun 2022 and 19 Jun 2022, thank you for your support!</div> */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(data)}>
          { t("imptMsg") }
          <Grid container spacing={3}>
            <div className={classes.checkbox}>
              <RadioGroup aria-label="quiz" name="quiz" value={state.collectionMethod} onChange={handleRadioCollectionChange} row>
                <FormControlLabel value={DELIVERY_METHOD} control={<Radio />} label={t("cDelivery")} />
                <FormControlLabel value={PICKUP_METHOD} control={<Radio />} label={t("cSelfCollect")} />
              </RadioGroup>
            </div>
            { state.collectionMethod === PICKUP_METHOD && 
            <div className={classes.checkbox}>
              <br />
              {t("cPickup")}
              <RadioGroup aria-label="quiz" name="quiz" value={state.collectionPlace} onChange={handleRadioPlaceChange} row>
                <FormControlLabel value={PICKUP_CHINATOWN} control={<Radio />} label={t("cChinatown")} />
              </RadioGroup>
            </div>  }
            { state.collectionMethod === PICKUP_METHOD && state.collectionPlace === PICKUP_CHINATOWN &&
            <div className={classes.checkbox, classes.pinkNotes}>{t("cChinatownTime")}</div> }

            <div className={classes.checkbox}>
              <br />
              <TextField
                id="date"
                label={t("cDate")}
                type="date"
                value={state.chosenDate}
                onChange={handleChangeDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </div>

            { state.collectionMethod === DELIVERY_METHOD &&
            <div className={classes.checkbox}>
              <br />
              {t("cPrefSession")}<br />
              {t("cPrefDisclaim")}
              <RadioGroup aria-label="quiz" name="quiz" value={state.preferredSession} onChange={handleRadioSessionChange} row>
                <FormControlLabel value={PREFERRED_AFTERNOON} control={<Radio />} label={t("cPM")} />
              </RadioGroup>
            </div> }
            { state.collectionMethod === DELIVERY_METHOD &&
            <FormInput name="postal" required postalType label={t("cPostal")} errorText={methods.errors.postal?.type} /> }
            { state.collectionMethod === DELIVERY_METHOD &&
            <FormInput name="shop" label={t("cShop")} errorText={methods.errors.shop?.type} /> }
            { state.collectionMethod === DELIVERY_METHOD &&
            <FormInput name="address" required label={t("cStreet")} errorText={methods.errors.address?.type} /> }
            
            { state.collectionMethod === DELIVERY_METHOD &&
            <FormInput promocodeType name="promocode" label={t("cPromocode")} errorText={methods.errors.promocode?.type} /> }            

            <div className={classes.checkbox}>
            {watchPostalCode.length === 6 && 
              <p className={classes.fee}>{t("cDeliveryFee")} S${state.deliveryCharge}. </p>
             }

            {state.promoCodeAccepted !== null && 
              <p className={classes.deliveryPromo}>{state.promoCodeAcceptedMsg}</p>
             }
             </div>

            { state.collectionMethod === DELIVERY_METHOD &&
            <div className={classes.checkbox}>
              <FormControlLabel
                control={<PinkCheckbox checked={state.contactlessAck} onChange={checkContactless} name="contactlessAck" />}
                label={t("cContactless")}
              />
            </div> }
            </Grid>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} variant="outlined" to="/cart">{t("btnBackToCart")}</Button>
            <div>
            <Button type="button" variant="contained" style={{
        backgroundColor: "#509AF9",
        color: 'white',
        marginRight: 10
    }} onClick={backStep}>{t("btnBack")}</Button>
            <Button style={{ marginLeft: 10 }} type="submit" variant="contained" style={{
        backgroundColor: "#2ACB96",
        color: 'white'
    }}>{t("btnNext")}</Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default CollectionForm;
